@font-face {
    font-family: Courier New; /* Имя шрифта */
    src: url("/common/app/./assets/fonts/Courier New.ttf"); /* Путь к файлу со шрифтом */
}

.btn {
    font-family: Roboto, "Courier New", sans-serif;
}

*:focus {
    outline: none !important;
    box-shadow: none !important;
}

.img-icon-wrapper {
    position: relative;
}

#main-logo {
    width: 100px;
    height: 100px;
    position: relative;
    top: -30px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.2) 0 3px 7px -2px;
    cursor: pointer;
}

.img-logo {
    width: 100px;
    padding: 10px;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-align-justify {
    text-align: justify;
}

.bg-color-grey {
    background-color: #f8f8f8;
}

.border-grey-1 {
    border: 1px solid #e7e7e7;
}

.debug-field {
    color: #fff;
    background-color: #FF3737;
}

div.divider {
    padding: 0;
    margin: 0;
    height: 1px;
    width: 100%;
    border-bottom: 1px solid #d9d9d9;
}

#text-header-page {
    font-size: 2.5rem;
}

.headers-text-color {
    color: #757575;
}

.headers-text-color i {
    color: #007bff;
    cursor: pointer;
}

input:not(.form-control-warning):hover,
input:not(.form-control-warning):active,
input:not(.form-control-warning):focus,
select:hover,
select:active,
select:focus {
    color: #007bff !important   ;
    border-color: #007bff !important;
}

.d-events:not(.btn) {
    pointer-events: none !important;
    background-color: #f5f5f5 !important;
}

.d-events.btn {
    pointer-events: none !important;
}

.d-events::-webkit-input-placeholder { /* Edge */
    color: #b9b9b9;
}

.d-events:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #b9b9b9;
}

.d-events::placeholder {
    color: #b9b9b9;
}

.opacity-20 {
    opacity: .2 !important;
}

.alert-custom {
    font-family: "Courier New", -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    color: #818182;
    background-color: #fefefe;
    border-color: #e5e5e5;
}

.alert-custom-secondary {
    color: #818182;
    background-color: #fefefe;
    border-color: #e5e5e5;
}

.btn-grey {
    color: #555555;
    background-color: #dfdfdf;
    background-image: none;
    border-color: #dfdfdf;
}

.btn-grey:disabled:hover {
    color: #555555;
}

.btn-grey:not(:disabled):hover {
    color: #555555;
    background-color: #cdcdcd;
    border-color: #cdcdcd;
}

.btn-grey:not(:disabled):active {
    color: #555555;
    background-color: #bbb;
    border-color: #bbb;
}

.btn-grey i {
    color: #aaa;
    margin-right: 5px;
}

.btn-red {
    color: #ffffff;
    background-color: #eb0000;
    border-color: #eb0000;
}

.btn-red:hover {
    color: #ffffff;
    background-color: #d60000;
    border-color: #d60000;
}

.btn-red:active {
    color: #ffffff;
    background-color: #b80000;
    border-color: #b80000;
}

.elevation-custom {
    box-shadow: rgba(0, 0, 0, 0.2) 0 3px 7px -2px;
}

.elevation-active-2 {
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12) !important;
}

.elevation-active-2:active {
    box-shadow: 0 0 0 0 rgba(0,0,0,.2), 0 0 0 0 rgba(0,0,0,.14), 0 0 0 0 rgba(0,0,0,.12) !important;
}

.loader-wrapper {
    padding: 5px 40px;
    background-color: #fefefe;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
}

.modal-custom {
    background-color: transparent;
}

.modal-content-custom {
    /*font-family: "Courier New", -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";*/
    background-color: #fefefe;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
}

.modal-header-custom {
    font-size: 1.25rem;
}

#span-modal-header-badge {
    padding: 10px 10px 8px 10px;
}

.badge-warning,
.btn-warning {
   color: #ffffff;
}

.btn-primary-custom {
    color: #ffffff;
    background-color: #007bff;
    border-color: #007bff;
}

.btn-primary-custom:hover {
    color: #ffffff;
    background-color: #0069d9;
    border-color: #0069d9;
}

.btn-primary-custom:active {
    color: #ffffff;
    background-color: #0062cc;
    border-color: #0062cc;
}

.btn-warning-custom {
    color: #ffffff;
    background-color: #ffc107;
    border-color: #ffc107;
}

.btn-warning-custom:hover {
    color: #ffffff;
    background-color: #ffc107;
    border-color: #ffc107;
}

.btn-warning-custom:active {
    color: #ffffff;
    background-color: #ffc107;
    border-color: #ffc107;
}

span.input-group-text,
label.input-group-text {
    background-color: #fafbfc;
}

span.input-group-text:not(.not-hide),
label.input-group-text:not(.not-hide) {
    display: none;
}

span.input-group-text:not(.ignore),
label.input-group-text {
    width: 100px;
}

.order-span-support span.input-group-text:not(.ignore),
.order-span-support label.input-group-text:not(.sum-support):not(.ignore) {
    width: 140px !important;
}

.sum-support {
    width: 80px !important;
}

#text-header-description-page {
    font-size: 1rem;
}

#div-about-status-disclaimer {
    font-size: 0.8rem;
}

.badge-grey {
    text-transform: uppercase;
    color: #6c757d;
    background-color: #fafbfc;
    border: 1px solid #ced4da;
}

#span-current-sum-append {
    min-width: 42px;
}

.custom-control {
    outline: none !important;
    box-shadow: none !important;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #ffc107;
    background-color: #ffc107;
    outline: none !important;
    box-shadow: none !important;
}

.custom-control-input~.custom-control-label::before {
    color: #fff;
    border-color: #ffc107;
    outline: none !important;
    box-shadow: none !important;
}

.input-group-text-primary {
    color: #ffffff;
    background-color: #007bff !important;
    border: 1px solid #007bff !important;
    margin-left: -2px !important;
}

.form-control-primary {
    color: #007bff;
    background-color: #ffffff !important;
    border: 1px solid #007bff !important;
}

.input-group-text-warning {
    color: #ffffff;
    background-color: #ffc107 !important;
    border: 1px solid #ffc107 !important;
    margin-left: -2px !important;
}

.form-control-warning {
    color: #ffc107;
    background-color: #ffffff !important;
    border: 1px solid #ffc107 !important;
}

.btn-primary-custom:disabled,
.btn-warning-custom:disabled {
    color: #757575;
    background-color: #f5f5f5 !important;
    border-color: #ced4da !important;
}

.bg-loading-grey {
    color: rgba(255, 255, 255, 0);
    background-color: transparent !important;
    background-image: linear-gradient(45deg, #fff 25%, #ced4da 25%, #ced4da 50%, #fff 50%, #fff 75%, #ced4da 75%, #ced4da) !important;

    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite;

    pointer-events: none !important;
}

.input-bg-loading-warning {
    color: rgba(255, 255, 255, 0);
    background-color: transparent !important;
    background-image: linear-gradient(45deg, #fff 25%, #ffc107 25%, #ffc107 50%, #fff 50%, #fff 75%, #ffc107 75%, #ffc107) !important;

    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite;

    pointer-events: none !important;
}

.input-bg-loading-primary {
    color: rgba(255, 255, 255, 0);
    background-color: transparent !important;
    background-image: linear-gradient(45deg, #fff 25%, #007bff 25%, #007bff 50%, #fff 50%, #fff 75%, #007bff 75%, #007bff) !important;

    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite;

    pointer-events: none !important;
}

.bg-warning {
    background-color: #ffc107 !important;
}

.tippy-tooltip.light-theme {
    background-color: #ffffff !important;
    color: #555 !important;
    line-height: 1.4rem !important;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12) !important;
}

.tippy-content {
    padding: 10px !important;
    text-align: justify;
}

.tippy-content-description {
    display: inline-block;
    min-width: 100px !important;
}

div.tippy-content > div > span > i {
    color: #aaaaaa !important;
    min-width: 14px !important;
}

.tippy-text-center {
    text-align: center;
}

.dataTables_length {
    display: none;
}

.dataTables_length select {
    display: inline-block;
    width: auto;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current:not(.disabled):hover,
.dataTables_wrapper .dataTables_paginate .paginate_button:not(.disabled):hover,
.dataTables_wrapper .dataTables_paginate .paginate_button:not(.disabled):active {
    color: #ffffff !important;
    background: #007bff !important;
    border-color: #007bff !important;
    cursor: pointer;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:disabled {
    color: #ffffff !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
    color: #ced4da !important;
    background: #ffffff !important;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    color: #ced4da !important;
    border: 1px solid #ced4da;
    border-radius: 0.25rem !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
    color: #ced4da !important;
    background-color: #f5f5f5 !important;
    border: 1px solid #ced4da !important;
}

thead th {
    color: #6c757d;
    border-bottom: 0 !important;
}

table {
    border-bottom: 1px solid #ced4da !important;
}

table td:not(.ignore-font) {
    font-family: "Courier New", -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

table th {
    font-weight: bold !important;
}

table.disabled:not(th) tr {
    background-color: #f5f5f5 !important;
}

table tbody tr:hover {
    cursor: pointer;
    background-color: #ced4da !important;
}

.table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
    background-color: #fafafa;
}

.fix-height-dog-nail {
    min-height: 63px !important;
}

select {
    cursor: pointer;
}

code {
    color: #007bff !important;
}

.min-width-18 {
    min-width: 18px !important;
}

.min-width-110 {
    min-width: 110px !important;
}

.bg-edit-stripes-grey {
    outline: 4px solid rgba(206, 212, 218, 0.6);
    background: repeating-linear-gradient(
            45deg,
            #ffffff,
            #ffffff 10px,
            rgba(206, 212, 218, 0.1) 10px,
            rgba(206, 212, 218, 0.1) 20px
    );
}

.bg-edit-white {
    outline: 4px solid rgba(206, 212, 218, 0.6);
    background-color: #fff;
}

.element-bg-loading-grey {
    -webkit-animation-name: animation-bg-loading-grey;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-play-state: running;

    animation-name: animation-bg-loading-grey;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-play-state: running;
}

@-webkit-keyframes animation-bg-loading-grey {
    0%     {background-color: #ced4da;}
    100.0%  {background-color: #f8f8f8;}
}

@keyframes animation-bg-loading-grey {
    0%     {background-color: #ced4da;}
    100.0%  {background-color: #f8f8f8;}
}

#div-order {
    transition: all !important;
}

.no-wrap-overflow-hidden {
    white-space: nowrap;        /* Запрещаем перенос строк */
    overflow: hidden;           /* Обрезаем все, что не помещается в область */
    text-overflow: ellipsis;    /* Добавляем многоточие */
}

.max-width-85 {
    max-width: 85px !important;
}

.fixed-width-85 {
    display: inline-block;
    width: 85px !important;
    max-width: 85px !important;
}

.fixed-width-110 {
    display: inline-block;
    width: 110px !important;
    max-width: 110px !important;
}

.badge-danger-red {
    background-color: #eb0000;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: bold;
}

.div-main-order-delivery-message {
    border-left: 2px solid #eb0000;
    font-family: "Courier New", -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.div-main-order-delivery-message .badge {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.div-main-order-delivery-message i:not(.fa-ruble-sign) {
    color: #eb0000;
    position: relative;
    font-size: 10px;
    left: -5px;
}

.p-top-order-delivery-message {
    position: relative;
    top: -15px;
}

.p-bottom-order-delivery-message {
    position: relative;
    bottom: -15px;
}

input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-size: 0.8rem;
}
input::-moz-placeholder { /* Firefox 19+ */
    font-size: 0.8rem;
}
input:-ms-input-placeholder { /* IE 10+ */
    font-size: 0.8rem;
}
input:-moz-placeholder { /* Firefox 18- */
    font-size: 0.8rem;
}

.forgot-credentials-link {
    text-decoration: underline;
    color: #0069d9;
    font-size: 0.75rem;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    #main-logo {
        left: -50px;
        top: -50px;
    }
    #text-header-page {
        font-size: 3.5rem !important;
    }
    #text-header-description-page {
        font-size: 1.25rem;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    #main-logo {
        left: -50px;
        top: -30px;
    }
    #text-header-page {
        font-size: 3.5rem !important;
    }
    #text-header-description-page {
        font-size: 1.25rem;
    }
    #div-about-status-disclaimer {
        font-size: 1rem;
    }
    span.input-group-text:not(.not-hide),
    label.input-group-text:not(.not-hide) {
        display: flex;
    }
    span.input-group-text:not(.ignore),
    label.input-group-text {
        width: 100px;
    }
    input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        font-size: 1rem;
    }
    input::-moz-placeholder { /* Firefox 19+ */
        font-size: 1rem;
    }
    input:-ms-input-placeholder { /* IE 10+ */
        font-size: 1rem;
    }
    input:-moz-placeholder { /* Firefox 18- */
        font-size: 1rem;
    }
    .sum-support {
        width: 140px !important;
    }
    .forgot-credentials-link {
        font-size: 1rem;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    #main-logo {
        left: -50px;
        top: -30px;
    }
    #text-header-page {
        font-size: 3.5rem !important;
    }
    #text-header-description-page {
        font-size: 1.25rem;
    }
    #div-about-status-disclaimer {
        font-size: 1rem;
    }
    span.input-group-text:not(.not-hide),
    label.input-group-text:not(.not-hide) {
        display: flex;
    }
    span.input-group-text:not(.ignore),
    label.input-group-text {
        width: 100px;
    }
    input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        font-size: 1rem;
    }
    input::-moz-placeholder { /* Firefox 19+ */
        font-size: 1rem;
    }
    input:-ms-input-placeholder { /* IE 10+ */
        font-size: 1rem;
    }
    input:-moz-placeholder { /* Firefox 18- */
        font-size: 1rem;
    }
    .sum-support {
        width: 140px !important;
    }
    .forgot-credentials-link {
        font-size: 1rem;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    #main-logo {
        left: -65px;
        top: -30px;
    }
    #text-header-page {
        font-size: 3.5rem !important;
    }
    #text-header-description-page {
        font-size: 1.25rem;
    }
    #div-about-status-disclaimer {
        font-size: 1rem;
    }
    span.input-group-text:not(.not-hide),
    label.input-group-text:not(.not-hide) {
        display: flex;
    }
    span.input-group-text:not(.ignore),
    label.input-group-text {
        width: 100px;
    }
    input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        font-size: 1rem;
    }
    input::-moz-placeholder { /* Firefox 19+ */
        font-size: 1rem;
    }
    input:-ms-input-placeholder { /* IE 10+ */
        font-size: 1rem;
    }
    input:-moz-placeholder { /* Firefox 18- */
        font-size: 1rem;
    }
    .sum-support {
        width: 140px !important;
    }
    .forgot-credentials-link {
        font-size: 1rem;
    }
}
/* --- MEDIA QUERY END ---  */
